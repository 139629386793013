import React from "react";
export default function Development() {
    return (
        <>
            <div className="development-section cSection">
                <div className="cContainer">
                    <div className="development-wrapper">
                        <div className="devTitle">
                            <h2 className="section-title">Our design and
                                <span>Development approach</span></h2>
                        </div>
                        <div className="development-data">
                            {Detils.map((d) => (
                                <div className="data-wrap">
                                    <div className="development-details" >
                                        <div className="data-img">
                                            <img src={d.img} alt="" />
                                        </div>
                                        <div className="details">
                                            <h4>{d.title}</h4>
                                            <p className="subtext" dangerouslySetInnerHTML={{ __html: d.text }}></p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const Detils = [
    {
        img: require('../asstes/ux-icon.svg').default,
        title: 'UX Driven Engineering',
        text: `At Spinnennetz, user experience is our compass. We engineer software with a relentless focus on UX, ensuring that every interaction feels intuitive and delightful. From initial concepts to the final product, our commitment to user-centric design sets your software apart.`,
    },
    {
        img: require('../asstes/code-icon.svg').default,
        title: 'Developing Shared Understanding',
        text: `Communication is the key to success. At Spinnennetz, we prioritize building a shared understanding with our clients. Transparent collaboration and open dialogue form the bedrock of our process, ensuring that your vision aligns seamlessly with our development efforts.`,
    },
    {
        img: require('../asstes/proven-icon.svg').default,
        title: 'Proven Experience and Expertise',
        text: `Experience matters. With a proven track record in diverse projects, Spinnennetz brings a blend of industry knowledge and technical expertise to the table. Choose us for reliable, scalable, and innovative software solutions backed by years of successful deliveries.`,
    },
    {
        img: require('../asstes/security-icon.svg').default,
        title: 'Security & Intellectual Property (IP)',
        text: `Trust is paramount. Spinnennetz takes your security and intellectual property seriously. Our robust measures ensure the confidentiality and integrity of your data and ideas throughout the development journey, giving you peace of mind and control over your innovations.
`,
    },
    {
        img: require('../asstes/code-review-icon.svg').default,
        title: 'Code Reviews',
        text: `Quality is in the details. Spinnennetz prioritizes rigorous code reviews. Our team collaborates to ensure every line of code meets the highest standards for efficiency, readability, and maintainability. This meticulous process ensures a codebase that stands strong against challenges.`,
    },
    {
        img: require('../asstes/quality-icon.svg').default,
        title: 'Quality Assurance & Testing',
        text: `Excellence is non-negotiable. Spinnennetz embeds quality assurance into every development phase. Our dedicated QA team conducts thorough testing, leaving no room for errors. The result? A product that not only meets specifications but exceeds expectations in reliability, performance, and user satisfaction.`,
    }

]