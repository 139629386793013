

import React, { useState } from 'react'


export default function PlanTable(key) {
    const [currency, setCurrency] = useState('INR');

    const handleCurrencyChange = (selectedCurrency) => {
        setCurrency(selectedCurrency);
    };
    // const [selected, setSelected] = useState("");
    // const changeHandler = e => {
    //     setSelected(e.target.value);
    // };

    return (
        <div className="plan-section cSection">
            <div className="cContainer">
                <div className="plan-wrapper">
                    <table className=' table main-table'>
                        <thead>
                            <tr>
                                <th className='table-heading odd-bg'>PLAN</th>
                                <th className='table-heading even-bg'>Store 1</th>
                                <th className='table-heading odd-bg'>Store 2</th>
                                <th className='table-heading even-bg'>Store 3</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((i) => (
                                < tr >
                                    <td className="table-inner-text" dangerouslySetInnerHTML={{ __html: i.titleText }}></td>
                                    <td className="table-inner-text"><span className="check-icon" dangerouslySetInnerHTML={{ __html: i.col1 }}></span></td>
                                    <td className="table-inner-text"><span className="check-icon" dangerouslySetInnerHTML={{ __html: i.col2 }}></span></td>
                                    <td className="table-inner-text"><span className="check-icon" dangerouslySetInnerHTML={{ __html: i.col3 }}></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className='price-btn'>
                        <ul>
                            <li className="btnList">
                                <button type="radio" value='INR' onClick={() => handleCurrencyChange('INR')} className={`cBtn btn ${currency === 'INR' ? 'active' : ''}`}>INR</button>
                            </li>
                            <li className='btnList'>
                                <button type="radio" value='USD' onClick={() => handleCurrencyChange('USD')} className={`cBtn btn ${currency === 'USD' ? 'active' : ''}`} >USD</button>
                            </li>
                        </ul>
                    </div>

                    <table className={`price-table main-table NRI`}>
                        <tbody>
                            {priceTable[currency].map((p) => (
                                < tr >
                                    <td className="table-inner-text PriceTitle" dangerouslySetInnerHTML={{ __html: p.titleText }}></td>
                                    <td className="table-inner-text"><span className="check-icon" dangerouslySetInnerHTML={{ __html: p.col1 }}></span></td>
                                    <td className="table-inner-text"><span className="check-icon" dangerouslySetInnerHTML={{ __html: p.col2 }}></span></td>
                                    <td className="table-inner-text"><span className="check-icon" dangerouslySetInnerHTML={{ __html: p.col3 }}></span></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    
                </div>
                <div className='shopify-area'>
                        <p className='shopify-text'><span>Shopify Pricing for INDIA</span><a href="https://www.shopify.com/in/pricing" target="_blank">www.shopify.com/in/pricing</a></p>
                        <p className='shopify-text'><span>Shopify Pricing for WORLDWIDE</span><a href="https://www.shopify.com/" target="_blank">www.shopify.com</a></p>
                        <p></p>
                    </div>
            </div>
        </div >
    )
}
const IconTRue = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_258_8540)">
<path d="M10.0002 15.172L19.1922 5.979L20.6072 7.393L10.0002 18L3.63623 11.636L5.05023 10.222L10.0002 15.172Z" fill="#57007B"/>
</g>
<defs>
<clipPath id="clip0_258_8540">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>
`
const tableData = [{
    "titleText": `<a target="_blank" href="https://themes.shopify.com/themes?sort_by=most_recent&price%5B%5D=free">Free Theme</a>`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `<a target="_blank" href="https://themes.shopify.com/themes?sort_by=most_recent&price%5B%5D=paid">Paid Theme</a>`,
    'col1': 'Add-on',
    'col2': 'Add-on',
    'col3': 'Add-on',
},
{
    "titleText": `<a target="_blank" href="https://apps.shopify.com/">Any Extension/ Plugin/ Application </a>`,
    'col1': 'Add-on',
    'col2': 'Add-on',
    'col3': 'Add-on',
},
{

    "titleText": `HomePage Banners`,
    'col1': ' 2',
    'col2': '4',
    'col3': '6',
},
{
    "titleText": `HomePage Banner Video`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Revisions - Graphic + HTML`,
    'col1': ' 1',
    'col2': '2',
    'col3': '3',
},
{
    "titleText": ` Collection`,
    'col1': '6',
    'col2': '12',
    'col3': '18',
},
{
    "titleText": `Product Editing & Listing `,
    'col1': '15',
    'col2': '30',
    'col3': '45',
},
{
    "titleText": `Team Level`,
    'col1': 'Skilled',
    'col2': 'Professional',
    'col3': 'Expert',
},
{
    "titleText": `Testing In Browser`,
    'col1': 'Chrome',
    'col2': `Firefox <br>Chrome`,
    'col3': 'Edge <br>Firefox <br>Chrome',
},
{
    "titleText": `Custom Product Page`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Mega Menu Setup`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Facebook Pixel`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Chat`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `WordPress Blog With 3 Blog Content <br> (Server Cost 8100₹ per year)`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Old Web URL Optimization`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Google Language Translator`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
}, {
    "titleText": `Custom Store Email Template Design`,
    'col1': '-',
    'col2': '1',
    'col3': '2',
}, {
    "titleText": `SEO Basic for Web Pages <br>Basic keyword research &amp; selection <br> Title tags optimization <br>Meta tag optimization <br>XML site map <br>Sitemap.xml <br>Robots.txt <br> Website indexing using Google search console`,
    'col1': '-',
    'col2': '10',
    'col3': '20',
}, {
    "titleText": `Local SEO / Business Listing`,
    'col1': '-',
    'col2': IconTRue,
    'col3': IconTRue,
}, {
    "titleText": `Custom Section - Home Page`,
    'col1': '2',
    'col2': '4',
    'col3': '6',
}, {
    "titleText": `Market/Country`,
    'col1': '1',
    'col2': '1',
    'col3': '2',
}, {
    "titleText": `Shopify Blog`,
    'col1': '-',
    'col2': IconTRue,
    'col3': IconTRue,
}, {
    "titleText": `404 Error Optimization`,
    'col1': '-',
    'col2': IconTRue,
    'col3': IconTRue,
}, {
    "titleText": `WWW - One URL For SEO`,
    'col1': '-',
    'col2': IconTRue,
    'col3': IconTRue,
}, {
    "titleText": `Non Broken Link`,
    'col1': '-',
    'col2': IconTRue,
    'col3': IconTRue,
}, {
    "titleText": `HomePage Mobile Banner If Theme Supported`,
    'col1': '-',
    'col2': IconTRue,
    'col3': IconTRue,
}, {
    "titleText": `Footer Menu`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
}, {
    "titleText": `Privacy Policy`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Refund Policy`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Shipping Policy`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Term and Condition`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Contact Details`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Order Cart/Checkout Page`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Domain Setup`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Theme Suggestion`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `CTA`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Check Out setting`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Taxes Setting`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Gift Card Setting`,
    'col1': '-',
    'col2': '-',
    'col3': IconTRue,
},
{
    "titleText": `Set Themes Color Combination According To Logo`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Finding Free Images Related To Contents`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Site Search`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Free  SSL Certificate & Installation`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Unlimited Product/Item To Be Uploaded By Client`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Google Analytics Setup`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Social Media Networking Linking`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Sticky Menu`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `WhatsApp Share`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Favicon. Icon`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
{
    "titleText": `Testing / Quality Check`,
    'col1': IconTRue,
    'col2': IconTRue,
    'col3': IconTRue,
},
]
const priceTable = {
    "INR": [{
        "titleText": `Ecommerce Plan`,
        'col1': 'SHOP1',
        'col2': 'SHOP2',
        'col3': 'SHOP3',
    },
    {
        "titleText": `One-time cost`,
        'col1': '₹ 30,000',
        'col2': '₹ 60,000',
        'col3': '₹ 90,000',
    }
    ],
    "USD": [{
        "titleText": `Ecommerce Plan`,
        'col1': 'SHOP1',
        'col2': 'SHOP2',
        'col3': 'SHOP3',
    },
    {
        "titleText": `One-time cost`,
        'col1': '$ 500',
        'col2': '$ 1000',
        'col3': '$ 1500',
    }
    ]
}

