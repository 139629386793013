

import React from 'react'
import ThankyouData from '../components/thankYou';
export default function ThankYouContact() {
    return (
       <>
        <ThankyouData />
       </>
    )
}