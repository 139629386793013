import React from 'react'
export default function ErrorPage() {
    return (
        <>
            <div className='comingsoon-section error'>
                <div className='cContainer'>
                    <div className='comingWrapper'>
                       <div className='sent-icon E404'>
                        <img src={require('../asstes/404-img.png')} alt='404 Image' />
                       </div>
                        {/* <h1>404 - Page Not Found</h1> */}
                        <h1 class="title"><span>404 - <b>Page Not Found</b></span></h1>
                        <p >The page you are looking for might have been removed had itsname changed or is temporarily unavailable.</p>
                        <div className='btnWrapper'>
                            <a href='/' className="cBtn btn">Go Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </> 
    )
}