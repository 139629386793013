import React from "react";
import TrophyImg from '../asstes/Trophy-icon.svg'
export default function Alcaline() {
    return (
        <>
            <div className="work-section cSection">
                <div className="cContainer">
                    <div className="work-wrapper">
                        <div className="workTitle">
                            <h2 className="section-title">How development
                                <span>Through Alcaline works</span></h2>
                        </div>
                        <div className="workDetails">
                            <div className="workContent workContent1">
                                {Workdata.map((d) => (
                                    <div className="workdata">
                                        <h5><span>{d.number}</span>{d.title}</h5>
                                        <p className="subtext">{d.text}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="midLine">
                                <span></span>
                                <img src={TrophyImg} alt="Trophy Image" />
                            </div>
                            <div className="workContent workContent2">
                                {Workdata2.map((d) => (
                                    <div className="workdata">
                                        <h5><span>{d.number}</span>{d.title}</h5>
                                        <p className="subtext">{d.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="workDetails tab">
                            <div className="workContent">
                                {workdataTAb.map((d) => (
                                    <div className="workdata">
                                        <h5><span>{d.number}</span>{d.title}</h5>
                                        <p className="subtext">{d.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const Workdata = [
    {
        number: '#1',
        title: 'Assemble the right team',
        text: `We handle all aspects of vetting and choosing the right team that you don't have the time, expertise, or desire to do.`,
    },
    {
        number: '#3',
        title: 'Tech architecture',
        text: `We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.`,
    },
    {
        number: '#5',
        title: 'Code reviews',
        text: `Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.`,
    },
]
const Workdata2 = [
    {
        number: '#2',
        title: 'Sprint planning',
        text: `Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.`,
    },
    {
        number: '#4',
        title: 'Standups & weekly demos',
        text: `Standups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.`,
    },
    {
        number: '#6',
        title: 'Iterative delivery',
        text: `We divide the implementation process into several checkpoints rather than a single deadline.`,
    },
]
const workdataTAb = [
    {
        number: '#1',
        title: 'Assemble the right team',
        text: `We handle all aspects of vetting and choosing the right team that you don't have the time, expertise, or desire to do.`,
    },
    {
        number: '#2',
        title: 'Sprint planning',
        text: `Sprint roadmap is a collective planning effort. Team members collaborate to clarify items and ensure shared understanding.`,
    },
    {
        number: '#3',
        title: 'Tech architecture',
        text: `We break monolithic apps into microservices. Decoupling the code allows teams to move faster and more independently.`,
    },
    {
        number: '#4',
        title: 'Standups & weekly demos',
        text: `Standups, weekly demos, and weekly reviews make sure everyone is on the same page and can raise their concerns.`,
    },
    {
        number: '#5',
        title: 'Code reviews',
        text: `Code reviews before release help detect issues like memory leaks, file leaks, performance signs, and general bad smells.`,
    },
    {
        number: '#6',
        title: 'Iterative delivery',
        text: `We divide the implementation process into several checkpoints rather than a single deadline.`,
    }
]