import React from "react";
export default function Build() {
    return (
        <>
            <div className="build-section cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="title">
                            <h2 className="section-title"> Way of building<span> Great Web</span></h2>
                        </div>
                        <div className="build-data">
                            {BuildData.map((i) => (
                                <div className="dataWrapper">
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <p className="para">{i.para}</p>
                                        </div>
                                        <ul className="Review">
                                            <li><p className="para">{i.li1}</p></li>
                                            <li><p className="para">{i.li2}</p></li>
                                            <li><p className="para">{i.li3}</p></li>
                                        </ul>
                                    </div>
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const BuildData = [
    {
        img: require('../asstes/build-1.jpg'),
        title: 'Build the right team to scale',
        para: `Building exceptional web starts with assembling a team of skilled and dedicated professionals. At Spinnennetz, we understand the challenges of finding the right talent in today's competitive market. Our commitment is to help you build a team that not only meets but exceeds your expectations.`,
        li1: 'Strategic Talent Acquisition',
        li2: 'Cultural Fit and Process Alignment',
        li3: 'Long-Term Partnerships',
    },
    {
        img: require('../asstes/build-2.jpg'),
        title: 'Optimize Development Processes',
        para: `Efficient development processes are the backbone of successful web projects. At Spinnennetz, we bring a wealth of experience in optimizing development workflows to enhance productivity, reduce costs, and ensure timely delivery.`,
        li1: 'Agile Methodology',
        li2: 'Cost-Effective Delivery Model',
        li3: 'Transparent Communication',
    },
    {
        img: require('../asstes/build-3.jpg'),
        title: 'Harness Innovation for Sustainable Growth',
        para: `In a rapidly evolving tech landscape, harnessing innovation is key to staying ahead. Spinnennetz is dedicated to infusing creativity and cutting-edge technologies into your web solutions, ensuring they not only meet current standards but also stand the test of time.`,
        li1: 'Continuous Learning and Adaptation',
        li2: 'Salable Architecture',
        li3: 'Future-Proof Solutions',
    }
]