import React from "react";
export default function AboutData() {
    return (
        <>
            <div className="build-section about cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="build-data">
                            {dataData.map((i) => (
                                <div className="dataWrapper about">
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <p className="para">{i.para}</p>
                                        </div>
                                        <ul className="list-style-1">
                                            <li><p className="text"><span>{i.subTitle1}</span> {i.li1} </p></li>
                                            <li><p className="text"><span>{i.subTitle2}</span> {i.li2}</p> </li>
                                            <li><p className="text"><span>{i.subTitle3}</span> {i.li3} </p></li>
                                            <li><p className="text"><span>{i.subTitle4}</span> {i.li4} </p></li>
                                        </ul>
                                    </div>
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const dataData = [
    {
        img: require('../asstes/our-story-img.jpg'),
        title: 'Our Story',
        para: `Spinnennetz was founded on the belief that exceptional web is more than just code; it's about creating experiences that leave lasting impressions. Our journey began with a vision to build a company that not only delivers cutting-edge solutions but also fosters a collaborative and innovative culture.`,
    },
    {
        img: require('../asstes/mission-img.jpg'),
        title: 'Mission',
        para: `Our mission is simple yet powerful – to empower businesses through tailor-made web solutions that elevate their digital presence. We strive to be more than just a development partner; we aim to be a catalyst for your success, providing the technical prowess and strategic insights needed to thrive in today's ever-evolving tech landscape.`,
    },
    {
        img: require('../asstes/why-choose-img.jpg'),
        title: 'Why Choose Spinnennetz',
        subTitle1: 'Expertise:',
        subTitle2: 'Innovative Solutions:',
        subTitle3: 'Client-Centric Approach:',
        subTitle4: 'Long-Term Commitment:',
        li1: 'Our team brings a wealth of experience and proven expertise to the table, ensuring your projects are in the hands of seasoned professionals',
        li2: `We don't just build web; we craft solutions that stand out in the digital landscape, leveraging the latest technologies to drive your success.`,
        li3: 'Your satisfaction is our priority. We listen, adapt, and tailor our services to meet your unique needs, ensuring a partnership that grows with you.',
        li4: ' Unlike fleeting solutions, we are invested in building lasting partnerships. We stand by your side, adapting to your evolving needs for sustained success.',
    }
]
