import React from 'react'
export default function ThankyouData() {
    return (
        <>
            <div className='comingsoon-section'>
                <div className='cContainer'>
                    <div className='comingWrapper'>
                       <div className='sent-icon'>
                        <img src={require('../asstes/sent-email.svg').default} alt='sent-icon' />
                       </div>
                        <h2>Thanks for submitting!</h2>
                        <p>You message has been sent!!</p>
                        <div className='btnWrapper'>
                            <a href='/' className="cBtn btn">Go Home</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}