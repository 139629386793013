import React, { useEffect } from 'react';
import AboutDetail from '../components/about-details';
import AboutData from '../components/about-data';
import AboutLead from '../components/about-lead';
// import ComingSoon from '../components/comingSoon';

export default function About() {
    useEffect(() => {
        document.title = 'Spinnennetz - About';
    }, []);
    return (
        <>
            <AboutDetail />
            <AboutData />
            <AboutLead />
            {/* <ComingSoon /> */}
        </>
    );
}