import React from "react";
export default function BasiccroData() {
    return (
        <>
            <div className="build-section basicWeb cSection">
                <div className="cContainer">
                    <div className="what-is-cro">
                        <p className="cro-title">What is CRO?</p>
                        <p className="cro-text">Conversion Rate Optimization (CRO) is the process of improving your website to boost the percentage of visitors who complete a desired action, such as making a purchase, signing up for a newsletter, or filling out a contact form. CRO involves understanding how users interact with your site, identifying barriers to conversion, and implementing changes to overcome these barriers.</p>
                    </div>
                    <div className="build-wrapper">
                        <div className="build-data">
                            {designData.map((i) => (
                                <div className="dataWrapper cro">
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <ul className="list-style-1">
                                                {
                                                    i.list.map((j) => (
                                                        <li><p className="text"><span>{j.title}</span> {j.copy}</p></li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {
                                        <div className="rightBuild">
                                            <img src={i.img} alt={i.title} />
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const designData = [
    {
        img: require('../asstes/Audit-analysis.jpg'),
        title: `Initial Audit and Analysis`,
        link: `Initial Audit and Analysis`,
        list: [
            {
                "title": `Comprehensive Website Audit:`,
                "copy": `We start with a thorough audit of your website to identify areas for improvement.`,
            },
            {
                "title": `Data Analysis:`,
                "copy": `Analyze existing data to understand user behavior and identify patterns and trends.`,
            },
            {
                "title": `Heatmaps and Session Recordings:`,
                "copy": `Use tools like heatmaps and session recordings to visualize user interactions and pinpoint issues.`,
            }
        ]
    },
    {
        img: require('../asstes/testing.jpg'),
        title: `A/B Testing and Multivariate Testing`,
        link: `A/B Testing and Multivariate Testing`,
        list: [
            {
                "title": `Test Planning:`,
                "copy": `Develop a structured plan for A/B and multivariate tests to compare different versions of your website elements.`,
            },
            {
                "title": `Implementation:`,
                "copy": `Set up and run tests using industry-leading tools to determine the most effective changes.`,
            },
            {
                "title": `Analysis and Reporting:`,
                "copy": `Analyze test results to understand their impact on conversion rates and make data-driven decisions.`,
            }
        ]
    },
    {
        img: require('../asstes/Ux-design.jpg'),
        title: `User Experience (UX) Optimization`,
        link: `User Experience (UX) Optimization`,
        list: [
            {
                "title": `User Feedback and Surveys:`,
                "copy": `Gather feedback directly from users to understand their needs and pain points.`,
            },
            {
                "title": `UX Design Improvements:`,
                "copy": `Implement design changes to enhance the user experience and remove friction points.`,
            },
            {
                "title": `Usability Testing:`,
                "copy": `Conduct usability tests to validate changes and ensure they improve user satisfaction.`,
            }
        ]
    },
    {
        img: require('../asstes/Landing-page.jpg'),
        title: `Landing Page Optimization`,
        link: `Landing Page Optimization`,
        list: [
            {
                "title": `Design and Copy Optimization:`,
                "copy": `Create compelling designs and persuasive copy that align with your conversion goals.`,
            },
            {
                "title": `Call-to-Action (CTA) Enhancement:`,
                "copy": `Optimize CTAs to make them more visible and enticing to users.`,
            },
            {
                "title": `Form Optimization:`,
                "copy": `Simplify and streamline forms to reduce abandonment rates and increase submissions.`,
            }
        ]
    },
    {
        img: require('../asstes/Analytics.png'),
        title: `Analytics and Performance Tracking`,
        link: `Analytics and Performance Tracking`,
        list: [
            {
                "title": `Goal and Event Tracking:`,
                "copy": `Set up tracking for key goals and events using tools like Google Analytics.`,
            },
            {
                "title": `Custom Dashboards:`,
                "copy": `Create custom dashboards to monitor performance metrics and conversion rates in real-time.`,
            },
            {
                "title": `Regular Reporting:`,
                "copy": `Provide regular reports on CRO performance and insights for ongoing improvements.`,
            }
        ]
    },
    {
        img: require('../asstes/Why-choose-cro.jpg'),
        title: `Why Choose Our CRO Services?`,
        link: `Why Choose Our CRO Services?`,
        list: [
            {
                "title": `Data-Driven Approach:`,
                "copy": `Our strategies are based on comprehensive data analysis and user research, ensuring informed decisions.`,
            },
            {
                "title": `Expert Team:`,
                "copy": `Our team of CRO experts has extensive experience in optimizing websites across various industries.`,
            },
            {
                "title": `Proven Results:`,
                "copy": `We have a track record of helping businesses significantly increase their conversion rates and revenue.`,
            },
            {
                "title": `Continuous Improvement:`,
                "copy": `CRO is an ongoing process, and we continuously monitor and optimize your site to achieve the best results.`,
            }
        ]
    },
    {
        img: require('../asstes/Our-process.jpg'),
        title: `Our Process`,
        link: `Our Process`,
        list: [
            {
                "title": `Discovery and Goal Setting:`,
                "copy": `We begin by understanding your business goals and identifying key conversion points on your website.`,
            },
            {
                "title": `Audit and Research:`,
                "copy": `Conduct a detailed audit and gather data to identify areas for improvement.`,
            },
            {
                "title": `Strategy Development:`,
                "copy": `Develop a tailored CRO strategy based on our findings and your goals.`,
            },
            {
                "title": `Implementation and Testing:`,
                "copy": `Implement the changes and run tests to measure their impact.`,
            },
            {
                "title": `Analysis and Optimization:`,
                "copy": `Analyze test results, make data-driven decisions, and continuously optimize your website for better performance.`,
            }
        ]
    }

]