import React from 'react'
import ShopifyData from '../components/shopify-data.js'
import PlanTable from '../components/shopifyPlan.js'
export default function Ecommerce() {
    return (
        <>
            <ShopifyData />
            <PlanTable />
        </>
    );
}