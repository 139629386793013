import React, { useEffect } from 'react';
import Herosection from '../components/hero-section';
import ServiceOffer from '../components/Service-offer';
import Company from '../components/company';
import Review from '../components/Review';
import CaseStudy from '../components/caseStudy';
import Build from '../components/Build';
import Development from '../components/development';
import Tech from '../components/Tech';
import Alcaline from '../components/alcalinework';
import Leads from '../components/Lead';
const Home = () => {
    useEffect(() => {
        document.title = 'Spinnennetz - Home';
    }, []);
    return (
        <>
            {/* <img src={require('../asstes/bootstrap.svg').default} /> */}
            <Herosection />
            <ServiceOffer />
            <Company />
            <Review />
            <CaseStudy />
            <Build />
            <Development />
            <Tech />
            <Alcaline />
            <Leads />
        </>
    )
}

export default Home;