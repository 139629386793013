
export default function Company() {
    return (
        <>
            <div className="company-section cSection">
                <div className="cContainer">
                    <div className="comapny-wrapper">
                        <div className="left-details">
                            <h2 className="section-title left">Leading companies trust us <span>to develop Web solutions</span></h2>
                            <p>We <span>add development capacity</span> to tech teams. Our value isn’t limited to building teams but is equally distributed across the project lifecycle. We are a custom web development company that guarantees the successful delivery of your project.</p>
                            <a href="/about-us" className='information-Link'><span>See more Informations</span> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                <path d="M16.172 11.5L10.808 6.13605L12.222 4.72205L20 12.5L12.222 20.278L10.808 18.864L16.172 13.5H4V11.5H16.172Z" fill="#57007B" />
                            </svg> </a>
                        </div>
                        <div className="video-content">
                            <img src={require('../asstes/developing-img.jpg')} alt="Hero Image" />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}