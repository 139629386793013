
export default function WebMaintenanceDetail() {
    return (
        <>
            <div className='detail-section basicWeb  cSection'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><span>Comprehensive CMS</span><small> Solutions Tailored for Your Needs</small> </h1>
                        <p className='text-1'>we excel in providing comprehensive CMS (Content Management System) solutions that empower you to manage your website with ease. Our experienced team is proficient with a variety of CMS platforms, ensuring that you get the best tool suited for your business requirements.</p>
                    </div>
                </div>
            </div>
        </>
    )
}