import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function ServiceOffer() {
    const settingsService = {
        dots: true,
        prevArrow: false,
        nextArrow: false,
        centerPadding: 0,
        centerMode: true,
        autoplay: false,
        infinite: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    // centerMode: false,
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                },
            },
        ],

    };
    return (
        <>
            <div className="service-section cSection">
                <div className="cContainer">
                    <div className="service-wrapper">
                        <span>Tailored Solutions for Your Success</span>
                        <h2 className="section-title"><span>Our service</span></h2>
                        <Slider {...settingsService} className="main-slider">
                            {Data.map((d) => (
                                <div className="main-data">
                                    <div className="main-details">
                                        <div className="img-wraper">
                                            <div className="imgWrap">
                                                <img src={d.img} alt={d.name} />
                                            </div>
                                            <span>{d.name}</span>
                                        </div>
                                        <div className="data-content">
                                            <p className="subtext">{d.review}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </ Slider >
                    </div>
                </div>
            </div>
        </>
    )
}
const Data = [
    {
        img: require('../asstes/web-design.png'),
        name: 'Web Design & Development',
        review: 'Spinnennetz offers expert Web Design & Development services, creating bespoke online solutions tailored to your needs. From concept to launch, we bring your vision to life with precision and creativity.',
    },
    {
        img: require('../asstes/cms-icon.png'),
        name: 'CMS Website Development',
        review: `Unlock the power of dynamic content management with Spinnennetz's CMS website service.Whether it's WordPress, Marketo, HubSpot, or others, we customize solutions tailored to your unique needs.`,
    },
    {
        img: require('../asstes/shopify.png'),
        name: 'Shopify Ecommerce Store',
        review: `Crafting captivating Shopify stores tailored to your brand. We specialize in theme customization, feature integration, and optimization for seamless user experiences and increased conversions. Elevate your online business with Spinnennetz.`,
    },
    {
        img: require('../asstes/woocommerce.png'),
        name: 'Woocommerce Store',
        review: `Elevate your online business with Spinnennetz's WooCommerce store development service. We specialize in creating customized stores that reflect your brand, drive sales, and engage customers effectively.`,
    },
    {
        img: require('../asstes/ab-testing-icon.png'),
        name: 'A/B Test Development',
        review: `Optimize your digital strategy with Spinnennetz's A/B test development service. We meticulously design and execute tests to refine your online presence and maximize conversions for lasting success.`,
    },
    {
        img: require('../asstes/cro.png'),
        name: 'CRO Configuration & Goal Setup',
        review: `Boost your website's performance with Spinnennetz's CRO configuration & goal setup service. We fine-tune your digital strategy, implementing strategic configurations to drive conversions and achieve your business objectives.`,
    },
    {
        img: require('../asstes/ul-ux.jpg'),
        name: 'UI/UX Designing',
        review: `Transform your digital presence with Spinnennetz's UI/UX Designing service. We craft intuitive, visually appealing interfaces that enhance user experiences and elevate your brand's online performance.`,
    },
    {
        img: require('../asstes/figma.png'),
        name: 'Figma to HTML/CSS/JS',
        review: `Spinnennetz bridges design and development seamlessly with our Figma to HTML/CSS/JS service. We transform your Figma designs into pixel-perfect, responsive websites, ensuring a flawless user experience across all devices.`,
    }
]
