

import React, { useEffect } from 'react'
import WebMaintenanceDetail from '../components/web-maintenancedetail'
import WebmaintenanceData from '../components/web-maintenancedata'
import Leadmaintenanceweb from '../components/Lead-maintenance'
export default function CmsWeb() {
    useEffect(() => {
        document.title = 'Spinnennetz - Web Maintenance';
    }, []);
    return (
        <>
        <WebMaintenanceDetail />
        <WebmaintenanceData/>
        <Leadmaintenanceweb/>
        </>
    )
}