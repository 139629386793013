import React from 'react'

export default function BasicWebDetail() {
    return (
        <>
            <div className='detail-section basic cSection'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><span>Get Your</span><small> Online Presence Started</small> </h1>
                        <p className='text-1'>We specialize in creating beautiful, functional, and affordable websites that serve as the perfect introduction to your online journey. Our Basic Web Development package is designed for small businesses, startups, and individuals who need a straightforward yet effective online presence.</p>
                    </div>
                </div>
            </div>
        </>
    )
}