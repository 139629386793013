import React from 'react'

export default function Designdata(){
    return(
        <>
            <div className="build-section basicWeb cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="build-data">
                            {designData.map((i) => (
                                <div className="dataWrapper design">
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <p>{i.text}</p>
                                            <ul className="list-style-1">
                                            {
                                                    i.list.map((j) => (
                                                        <li><p className="text"><span>{j.title}</span> {j.copy}</p></li>
                                                    ))
                                            }   
                                            {/* <li><p className="text"><span>{i.subTitle1}</span> {i.li1} </p></li>
                                            <li><p className="text"><span>{i.subTitle2}</span> {i.li2}</p> </li>
                                            <li><p className="text"><span>{i.subTitle3}</span> {i.li3} </p></li>
                                            <li><p className="text"><span>{i.subTitle4}</span> {i.li4} </p></li> */}
                                        </ul>
                                        </div>
                                    </div>
                                    {
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div> 
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const designData = [
    {
        img: require('../asstes/UI-UX-Design.jpg'),
        title: `UI/UX Design`,
        link: `UI/UXDesign`,
        text:`Our UI/UX design services focus on creating intuitive, engaging, and user-friendly interfaces that enhance user satisfaction and drive conversions.`,
        list: [
            {
                "title": `User Research: `,
                "copy": `We conduct comprehensive user research to understand your audience and their needs.`,
            },
            {
                "title": `Wireframing and Prototyping: `,
                "copy": `Develop wireframes and prototypes to visualize the structure and flow of your website or app.`,
            },
            {
                "title": `Visual Design: `,
                "copy": ` Craft visually appealing designs that align with your brand identity and resonate with your users.`,
            },
            {
                "title": `Usability Testing: `,
                "copy": `Perform usability tests to ensure the design is intuitive and meets user expectations.`,
            },
        ]
    },
    {
        img: require('../asstes/Logo-Design.jpg'),
        title: `Logo Design`,
        link: `LogoDesign`,
        text:`Your logo is the face of your brand. Our logo design services help you create a distinctive and memorable logo that captures the essence of your business.`,
        list: [
            {
                "title": `Brand Discovery: `,
                "copy": `We start by understanding your brand values, mission, and target audience.`,
            },
            {
                "title": `Concept Development:`,
                "copy": `Generate multiple logo concepts that reflect your brand identity.`,
            },
            {
                "title": `Refinement:`,
                "copy": `Refine the chosen concept based on your feedback to ensure it perfectly represents your brand.`,
            },
            {
                "title": `Finalization: `,
                "copy": `Deliver the final logo in various formats for use across different platforms.`,
            },
        ]
    },
    {
        img: require('../asstes/Leaflet.jpg'),
        title: `Leaflet Design`,
        link: `LeafletDesign`,
        text:`Our leaflet design services help you create compelling and informative leaflets that capture attention and convey your message effectively.`,
        list: [
            {
                "title": `Content Planning:`,
                "copy": `Assist with organizing and structuring your content for maximum impact.`,
            },
            {
                "title": `Design and Layout:`,
                "copy": `Create visually appealing layouts that highlight key information and engage readers.`,
            },
            {
                "title": `Print-Ready Files:`,
                "copy": `Provide print-ready files that meet the highest quality standards for printing.`,
            }
        ]
    },
    {
        img: require('../asstes/social-media.png'),
        title: `Social Media Banners`,
        link: `SocialMediaBanners`,
        text:`Our social media banner design services help you create visually striking banners that enhance your social media profiles and drive engagement.`,
        list: [
            {
                "title": `Platform-Specific Designs:`,
                "copy": `Tailor designs to fit the specifications and requirements of various social media platforms.`,
            },
            {
                "title": `Brand Consistency:`,
                "copy": `Ensure your banners are consistent with your brand’s visual identity.`,
            },
            {
                "title": `Dynamic and Static Banners:`,
                "copy": `Create both dynamic (animated) and static banners to suit your needs.`,
            }
        ]
    }
    
]
