import React from 'react'
// import ComingSoon from '../components/comingSoon';

export default function ServiceDetail() {
    return (
        <>
            <div className='detail-section service cSection'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><span>Web Development</span><small> Company for smart solutions!</small> </h1>
                        <p className='text-1'>Welcome to Spinnennetz, where innovation meets expertise, and ideas take flight. Established with a passion for pushing the boundaries of software development, we are a dynamic team dedicated to transforming your visions into reality.</p>
                    </div>
                </div>
            </div>
        </>
    )
}