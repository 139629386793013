

import React from 'react'
import ErrorPage from '../components/error';
export default function OopsData() {
    return (
       <>
        <ErrorPage />
       </>
    )
}