import React from "react";
export default function ServiceData() {
    return (
        <>
            <div className="build-section service cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="build-data">
                            {dataData.map((i) => (
                                <div className="dataWrapper about">
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <p className="para">{i.para}</p>
                                        </div>
                                    </div>
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const dataData = [
    {
        img: require('../asstes/web-image.jpg'),
        title: 'Web App Development',
        para: `Unlock the potential of the web with our cutting-edge Web App Development services. At Spinnennetz, we craft dynamic, responsive, and scalable web applications tailored to meet your unique business needs. From intuitive user interfaces to robust backend architectures, we ensure a seamless digital experience that engages and delights users.`,
    },
    {
        img: require('../asstes/app-image.jpg'),
        title: 'Mobile App Development',
        para: `Step into the mobile-first era with Spinnennetz's Mobile App Development services. Whether you're targeting iOS or Android, our team specializes in creating feature-rich, user-friendly mobile applications. From concept to deployment, we navigate the complexities of mobile development to deliver apps that not only meet but exceed user expectations.`,
    },
    {
        img: require('../asstes/QA-image.jpg'),
        title: 'Quality Assurance & Testing',
        para: `Ensure the flawless performance of your software with our comprehensive Quality Assurance and Testing services. At Spinnennetz, we go beyond surface-level testing, conducting rigorous examinations to identify and eliminate potential issues. From functional testing to performance testing, our QA experts ensure that your software not only meets industry standards but exceeds them, providing a seamless experience for your users.`,
    }
]