import React from "react";

export default function Basicweb() {
    return (
        <>
            <div className="build-section basicWeb cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="build-data">
                            {dataData.map((i) => (
                                <div className="dataWrapper about">
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <ul className="list-style-1">
                                            <li><p className="text"><span>{i.subTitle1}</span> {i.li1} </p></li>
                                            <li><p className="text"><span>{i.subTitle2}</span> {i.li2}</p> </li>
                                            <li><p className="text"><span>{i.subTitle3}</span> {i.li3} </p></li>
                                            <li><p className="text"><span>{i.subTitle4}</span> {i.li4} </p></li>
                                            <li><p className="text"><span>{i.subTitle5}</span> {i.li5} </p></li>
                                            <li><p className="text"><span>{i.subTitle6}</span> {i.li6} </p></li>
                                            <li><p className="text"><span>{i.subTitle7}</span> {i.li7} </p></li>
                                            <li><p className="text"><span>{i.subTitle8}</span> {i.li8} </p></li>
                                        </ul>
                                        </div>
                                    </div>
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const dataData = [
    {
        img: require('../asstes/basic-package-collage.jpg'),
        title: 'What’s Included in Our Basic Web Package?',
        subTitle1:`Custom Design: `,
        subTitle2:`Responsive Layout: `,
        subTitle3:`User-Friendly Navigation: `,
        subTitle4:`Content Management System (CMS): `,
        subTitle5:`Basic SEO Optimization: `,
        subTitle6:`Contact Form: `,
        subTitle7:`Social Media Integration: `,
        subTitle8:`Google Maps Integration: `,
        li1: `We create a unique design tailored to your brand. Your website will stand out with a professional and polished look.`,
        li2: ` Our websites are fully responsive, ensuring they look great on any device.`,
        li3: 'Easy-to-use navigation ensures your visitors can find what they need quickly and effortlessly.',
        li4: ' Manage your own content with ease using a user-friendly CMS like WordPress.',
        li5: ' We implement essential SEO practices to help your website rank better in search engines and attract more visitors.',
        li6: ' A simple contact form to allow your visitors to get in touch with you directly from your website.',
        li7: ' Connect your social media profiles to your website to increase engagement and expand your reach.',
        li8: ' If you have a physical location, we’ll embed Google Maps so your visitors can easily find you.',
    },
    {
        // img: require('../asstes/why-choose-img.jpg'),
        img: require('../asstes/why-choose-us.jpg'),
        title: 'Why Choose Us?',
        subTitle1:`Affordable Pricing: `,
        subTitle2:`Quick Turnaround: `,
        subTitle3:`Expert Support: `,
        subTitle4:`Client-Centric Approach: `,  
        li1: `We create a unique design tailored to your brand. Your website will stand out with a professional and polished look.`,
        li2: ` Our websites are fully responsive, ensuring they look great on any device – be it a desktop, tablet, or smartphone.`,
        li3: 'Easy-to-use navigation ensures your visitors can find what they need quickly and effortlessly.',
        li4: ' Manage your own content with ease using a user-friendly CMS like WordPress. No technical skills required!',
    },
    {
        img: require('../asstes/development-process.png'),
        title: 'Our Process',
        subTitle1:`Custom Design: `,
        subTitle2:`Responsive Layout: `,
        subTitle3:`User-Friendly Navigation: `,
        subTitle4:`Content Management System (CMS): `,  
        subTitle5:`Content Management System (CMS): `,  
        li1: `We create a unique design tailored to your brand. Your website will stand out with a professional and polished look.`,
        li2: ` Our websites are fully responsive, ensuring they look great on any device – be it a desktop, tablet, or smartphone.`,
        li3: 'Easy-to-use navigation ensures your visitors can find what they need quickly and effortlessly.',
        li4: ' Manage your own content with ease using a user-friendly CMS like WordPress. No technical skills required!',
        li5: ' Manage your own content with ease using a user-friendly CMS like WordPress. No technical skills required!',
    }
]