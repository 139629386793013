

import React from 'react'

export default function WooCommData() {
    return (
        <div className="hero-section e-comm cSection">
            <div className="cContainer">
                <div className="hero-wrapper">
                    <div className="left-content">
                        <h2>WooCommerce</h2>
                        <h3>Payment and Shipping Integration</h3>
                        <p className='text-1'>Experts in perfecting the finer details of ecommerce</p>
                        <a href="/contact" className='cBtn btn'>Get started!</a>
                    </div>
                    <div className="right-content">
                        <img src={require('../asstes/woo-comm-image.png')} alt="Hero Image" />
                    </div>
                </div>
            </div>
        </div>
    )
}