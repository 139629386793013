import React from 'react'
// import ComingSoon from '../components/comingSoon';

export default function AboutDetail() {
    return (
        <>
            <div className='detail-section cSection'>
                <div className='cContainer'>
                    <div className='detailWrapper'>
                        <h1 class="title"><small>We are</small> <span>Spinnennetz</span></h1>
                        <p className='text-1'>Welcome to Spinnennetz, where innovation meets expertise, and ideas take flight. Established with a passion for pushing the boundaries of software development, we are a dynamic team dedicated to transforming your visions into reality.</p>
                    </div>
                </div>
            </div>
        </>
    )
}