import React from "react";

export default function Cmsweb() {
    return (
        <>
            <div className="build-section basicWeb cSection">
                <div className="cContainer">
                    <div className="build-wrapper">
                        <div className="build-data">
                            {dataData.map((i) => (
                                <div className="dataWrapper cms" id={i.link} data-id= {i.link}>
                                    <div className="leftBuild">
                                        <div className="details">
                                            <h3>{i.title}</h3>
                                            <ul className="list-style-1">
                                                {
                                                    i.list.map((j) => (
                                                        <li><p className="text"><span>{j.title}</span> {j.copy}</p></li>
                                                    ))
                                                }
                                                {/* <li><p className="text"><span>{i.subTitle1}</span> {i.li1} </p></li>
                                            <li><p className="text"><span>{i.subTitle2}</span> {i.li2}</p> </li>
                                            <li><p className="text"><span>{i.subTitle3}</span> {i.li3} </p></li>
                                            <li><p className="text"><span>{i.subTitle4}</span> {i.li4} </p></li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="rightBuild">
                                        <img src={i.img} alt={i.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const dataData = [
    {
        img: require('../asstes/web-flow.png'),
        title: `Webflow`,
        link: `Webflow`,
        list: [
            {
                "title": `Dynamic Design Freedom: `,
                "copy": `Our experts leverage Webflow to create visually stunning, responsive websites without the limitations of traditional templates.`,
            },
            {
                "title": `Easy Content Updates: `,
                "copy": `Enjoy a seamless content management experience with Webflow’s intuitive editor.`,
            },
            {
                "title": `Custom Interactions: `,
                "copy": `Engage your audience with sophisticated animations and interactions designed by our team.`,
            },
        ]
    },
    {
        img: require('../asstes/sitefinity.png'),
        title: `Sitefinity`,
        link: `Sitefinity`,
        list: [
            {
                "title": `Enterprise-Grade Performance: `,
                "copy": `Ideal for large businesses, Sitefinity offers robust features for scalability and security.`,
            },
            {
                "title": `Personalized Experiences:`,
                "copy": `We utilize Sitefinity’s powerful personalization tools to create unique user experiences tailored to your audience.`,
            },
            {
                "title": `Seamless Integration: `,
                "copy": `Our team ensures smooth integration with your existing systems and third-party applications.`,
            },
        ]
    },
    {
        img: require('../asstes/marketo.png'),
        title: `Marketo`,
        link: `Marketo`,
        list: [
            {
                "title": `Marketing Automation: `,
                "copy": `Enhance your marketing efforts with Marketo’s automation capabilities, from email campaigns to lead management.`,
            },
            {
                "title": `Analytics and Insights: `,
                "copy": `Gain valuable insights with Marketo’s comprehensive analytics tools, helping you refine your marketing strategies.`,
            },
            {
                "title": `Lead Nurturing: `,
                "copy": `Our team helps you set up effective lead nurturing workflows to convert prospects into customers.`,
            },
        ]
    },
    {
        img: require('../asstes/hubspot.png'),
        title: `HubSpot`,
        link: `HubSpot`,
        list: [
            {
                "title": `All-in-One Platform: `,
                "copy": `HubSpot’s comprehensive suite includes CRM, marketing, sales, and service tools, all in one place.`,
            },
            {
                "title": `Inbound Marketing Expertise: `,
                "copy": `Leverage our expertise to maximize your inbound marketing efforts using HubSpot’s robust tools.`,
            },
            {
                "title": `Custom Modules: `,
                "copy": `We develop custom HubSpot modules to meet your specific business needs and enhance functionality.`,
            },
        ]
    },
    {
        img: require('../asstes/wix-studio.png'),
        title: `Wix Studio`,
        link: `Wix`,
        list: [
            {
                "title": `User-Friendly Interface: `,
                "copy": `Wix Studio offers an easy-to-use interface, perfect for businesses looking to manage their own content effortlessly.`,
            },
            {
                "title": `Template Customization: `,
                "copy": `Our team customizes Wix templates to match your brand’s look and feel.`,
            },
            {
                "title": `App Integrations: `,
                "copy": `Enhance your site’s functionality with a variety of Wix apps, integrated seamlessly by our experts.`,
            },
        ]
    },
    {
        img: require('../asstes/wordpress.png'),
        title: `WordPress`,
        link: `Wordpress`,
        list: [
            {
                "title": `Versatile and Scalable: `,
                "copy": `WordPress is highly versatile, suitable for anything from blogs to enterprise-level websites.`,
            },
            {
                "title": `Custom Themes and Plugins: `,
                "copy": `We develop custom themes and plugins to provide functionality tailored to your business.`,
            },
            {
                "title": `SEO Optimization: `,
                "copy": `Our WordPress sites are optimized for search engines to help you rank higher and attract more visitors.`,
            },
        ]
    },
    {
        img: require('../asstes/unbounce.png'),
        title: `Unbounce`,
        link: `Unbounce`,
        list: [
            {
                "title": `Versatile and Scalable: `,
                "copy": `WordPress is highly versatile, suitable for anything from blogs to enterprise-level websites.`,
            },
            {
                "title": `Custom Themes and Plugins: `,
                "copy": `We develop custom themes and plugins to provide functionality tailored to your business.`,
            },
            {
                "title": `SEO Optimization: `,
                "copy": `Our WordPress sites are optimized for search engines to help you rank higher and attract more visitors.`,
            },
        ]
    },
    {
        img: require('../asstes/why-choose-cms.png'),
        title: `Why Choose Our CMS Development Services?`,
        list: [
            {
                "title": `Tailored Solutions: `,
                "copy": `We understand that every business is unique. Our solutions are tailored to meet your specific requirements and goals.`,
            },
            {
                "title": `Experienced Team: `,
                "copy": `Our team of experts has extensive experience with various CMS platforms, ensuring high-quality and efficient development.`,
            },
            {
                "title": `Ongoing Support: `,
                "copy": `We offer continuous support and maintenance to ensure your website remains up-to-date and performs optimally.`,
            },
            {
                "title": `Training and Empowerment: `,
                "copy": ` We provide comprehensive training so you can manage your website confidently and independently.`,
            },
        ]
    }
]