import React from 'react'
export default function HireData() {

    const handleClick = () => {
        window.scrollTo({
            top: 700,
            behavior: 'smooth' // Optional, adds smooth scrolling behavior
        });
    };
    return (
        <>
            <div className="hero-section hire cSection">
                <div className="cContainer">
                    <div className="hero-wrapper">
                        <div className="left-content">
                            <div className='contact-title'>
                                <h3>we're hiring</h3>
                                <h2>Join our team</h2>
                                <p className='text-1'>Let us help to achieve your vision</p>
                            </div>
                            <div className='btnWrapper'>
                              <a className='cBtn btn' onClick={handleClick}>Apply Now</a>
                            </div>
                        </div>
                        <div className="right-content">
                            <img src={require('../asstes/contact-us.jpg')} alt="Contact Us" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}