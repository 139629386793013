

import React, { useEffect } from 'react'
import WooCommData from '../components/woocomm-data.js'
import WooPlanTable from '../components/woocom-plan.js'
export default function WooCommerce() {
    useEffect(() => {
        document.title = 'Spinnennetz - WooCommerce Wordpress';
    }, []);
    return (
        <>
            <WooCommData />
            <WooPlanTable />
        </>
    )
}